import React, { useContext } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { IsLiveContext } from '../live/live-context'
// import { red, black } from 'src/gatsby-theme-material-ui-top-layout/theme'

type SidebarButtonProps = {
  isSidebarOpen: boolean
  toggleSidebar(isOpen?: boolean): void
}
const HamburgerButton = ({
  isSidebarOpen,
  toggleSidebar,
}: SidebarButtonProps) => {
  const { isLive } = useContext(IsLiveContext)

  return (
    <Button onClick={() => toggleSidebar()}>
      {/* <MenuIcon color="secondary" /> */}
      <MenuIcon
        color={isLive ? (isSidebarOpen ? 'secondary' : 'inherit') : 'secondary'}
        // style={{ color: isLive ? (isSidebarOpen ? red : black) : red }}
      />
    </Button>
  )
}

export default HamburgerButton

const Button = styled(IconButton)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${(p) => p.theme.zIndex.drawer + 101};
`
