type TimePieces = {
  days: string
  hours: string
  minutes: string
  seconds: string
  centiseconds: string
  timeDelta: number
  now: number
}
export const getTimePieces = (isCountUp: boolean, matchDate?: Date) => {
  const now = new Date().getTime()
  if (matchDate === undefined) {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      centiseconds: '00',
      timeDelta: 0,
      now,
    }
  }

  try {
    const unadjustedTimeDelta = matchDate.valueOf() - now.valueOf()
    const timeDelta = isCountUp
      ? Math.abs(-1 * unadjustedTimeDelta)
      : unadjustedTimeDelta

    const formatNum = (num: number) => num.toString().padStart(2, '0')

    const days = formatNum(Math.floor(timeDelta / (1000 * 60 * 60 * 24)))
    const hours = formatNum(
      Math.floor((timeDelta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    )
    const minutes = formatNum(
      Math.floor((timeDelta % (1000 * 60 * 60)) / (1000 * 60))
    )
    const seconds = formatNum(Math.floor((timeDelta % (1000 * 60)) / 1000))
    const centiseconds = formatNum(Math.floor((timeDelta % 1000) / 10))

    return {
      days,
      hours,
      minutes,
      seconds,
      centiseconds,
      timeDelta,
      now,
    }
  } catch (err) {
    console.error('error getting time pieces:', err)
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      centiseconds: '00',
      timeDelta: 0,
      now,
    }
  }
}
