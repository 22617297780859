import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import firebase from 'gatsby-plugin-firebase'
import { SettingsContext } from '../settings-context'
import NotificationSettings, {
  notificationTimes,
} from './notification-settings'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
import Collapse from '@material-ui/core/Collapse'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SettingsSublist from '../settings-sublist'

export type HandleShowErrMsg = (errReason?: string) => void

const Notifications = () => {
  const [errState, setErrState] = useState({ isShown: false, msg: '' })
  const [{ isEuroMode, notifications }, dispatch] = useContext(SettingsContext)

  const permissionsMsg = `You can't use notifications if you block notification permission.`
  const checkNotificationPermissions = async () => {
    await Notification.requestPermission()
    const arePermissionsBlocked = Notification.permission === 'denied'
    arePermissionsBlocked && setErrState({ isShown: true, msg: permissionsMsg })

    return arePermissionsBlocked
  }

  const handleShowErrMsg: HandleShowErrMsg = (errReason) => {
    const genericMsg = `There was an error updating your notification settings.
[Error:\xa0${errReason ?? 'unknown'}]`
    setErrState({
      isShown: true,
      msg: errReason === 'blocked' ? permissionsMsg : genericMsg,
    })
  }

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText>
          {isEuroMode ? 'Match' : 'Game'} Notifications
        </ListItemText>
        {firebase.messaging?.isSupported() && (
          <Switch
            edge="end"
            checked={Object.values(notifications).includes(true)}
            onChange={async (e, isChecked) => {
              const arePermissionsBlocked = await checkNotificationPermissions()
              notificationTimes.forEach((notificationTime) => {
                dispatch({
                  type: 'updateNotifications',
                  payload: {
                    isChecked,
                    notificationTime,
                    arePermissionsBlocked,
                    handleShowErrMsg,
                  },
                })
              })
            }}
          />
        )}
      </ListItem>
      <Collapse in={errState.isShown}>
        <ListItem>
          <Alert
            severity="error"
            // variant="filled"
            style={{ width: '100%', overflow: 'auto' }}
            onClose={() => setErrState({ isShown: false, msg: '' })}
          >
            <AlertTitle>Error</AlertTitle>
            {errState.msg}
          </Alert>
        </ListItem>
      </Collapse>
      <SettingsSublist>
        {firebase.messaging?.isSupported() ? (
          <NotificationSettings
            checkNotificationPermissions={checkNotificationPermissions}
            handleShowErrMsg={handleShowErrMsg}
          />
        ) : (
          <ListItem>
            <NotSupportedText>
              Your device/browser doesn't support push notifications
            </NotSupportedText>
          </ListItem>
        )}
      </SettingsSublist>
    </>
  )
}

export default Notifications

const NotSupportedText = styled(ListItemText)`
  font-style: italic;
  opacity: 0.85;
`
