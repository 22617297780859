import React, { useState, useContext } from 'react'
import Img from 'gatsby-image'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Icon from '@material-ui/core/Icon'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { FaAngry } from 'react-icons/fa'
import { SettingsContext } from '../settings-context'
import useJosef, {
  JosefOptions,
} from 'src/features/countdown/game-day-josef/use-josef'
import usePreviewJosefs from '../use-preview-josef'
import SettingsSublist from '../settings-sublist'

const JosefBackground = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [{ activeJosef, isEuroMode }, dispatch] = useContext(SettingsContext)
  const { josefFluidData, josefs } = usePreviewJosefs(activeJosef)

  const handleJosefChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    //this seems to be how Material-UI have set up these types to be used
    dispatch({
      type: 'updateSettings',
      payload: { activeJosef: e.target.value as JosefOptions },
    })
    setIsOpen(false)
  }

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <Icon style={{ overflow: 'visible' }}>
            <FaAngry alignmentBaseline="middle" />
          </Icon>
        </ListItemIcon>
        <ListItemText>{isEuroMode ? 'Match' : 'Game'} day Josef</ListItemText>
      </ListItem>

      <SettingsSublist>
        <ListItem style={{ justifyContent: 'space-between' }}>
          <FormControl>
            <Select
              id="set-josef"
              value={activeJosef}
              onChange={handleJosefChange}
              variant="outlined"
              open={isOpen}
              onOpen={toggleOpen}
              onClose={toggleOpen}
              style={{ minWidth: '135px' }}
            >
              {josefs.map((josef) => (
                <MenuItem key={josef} value={josef}>
                  {josef}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            onClick={toggleOpen}
            style={{ cursor: 'pointer' }}
            /* only for draggable cancel -- no styles */
            className="josef-preview"
          >
            <ListItemAvatar>
              <Img
                fixed={josefFluidData as any}
                draggable={false}
                fadeIn={false}
              />
            </ListItemAvatar>
          </div>
        </ListItem>
      </SettingsSublist>
    </>
  )
}

export default JosefBackground
