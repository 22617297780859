import React, {
  useReducer,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { JosefOptions } from 'src/features/countdown/game-day-josef/use-josef'
import { Action, reducer, dispatchMiddleware } from './settings-reducer'
import { getIsEuroTimezone } from './utils'
import firebase from 'gatsby-plugin-firebase'

type SettingsContextProps = {
  children: React.ReactNode
}
const SettingsProvider = ({ children }: SettingsContextProps) => {
  //leave the explicit type settings for easier debugging
  const [settings, dispatch] = useReducer<
    React.Reducer<Readonly<Settings>, Action>
  >(reducer, initialSettings)

  //update state from storage, writing defaults to storage if needed
  useEffect(() => {
    //TODO: I think this window check is redundant
    if (typeof window !== 'undefined') {
      const localSettingsString = localStorage.getItem('settings')
      if (localSettingsString != null) {
        const localSettings = JSON.parse(localSettingsString)

        //Set notifications to all be 'false' if permissions aren't granted;
        //for cases where user grants permissions but later revokes them.
        //Note: no need to update firebase subscription, because user
        //will be given new token if they re-allow notifications.
        if (firebase.messaging?.isSupported()) {
          if (
            Notification?.permission !== 'granted' &&
            Object.values(localSettings.notifications).includes(true)
          ) {
            Object.keys(localSettings.notifications).forEach(
              (key) => (localSettings.notifications[key] = false)
            )
            localStorage.setItem('settings', JSON.stringify(localSettings))
          }
        }

        dispatch({
          type: 'updateFromLocalstorage',
          payload: localSettings,
        })
      } else {
        localStorage.setItem('settings', JSON.stringify(defaultSettings))
        dispatch({
          type: 'updateFromLocalstorage',
          payload: defaultSettings,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //capture browser PWA install prompt
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        // alert('prompt detected/blocked')
        dispatch({ type: 'updateSettings', payload: { deferredPwaPrompt: e } })
      })
    }
  }, [])

  return (
    <SettingsContext.Provider value={[settings, dispatchMiddleware(dispatch)]}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider

export type BoolSetState = Dispatch<SetStateAction<boolean>>
export type JosefSetState = Dispatch<SetStateAction<JosefOptions>>
export type AutoplaySetState = Dispatch<SetStateAction<0 | 1>>

export const SettingsContext = createContext<[Settings, Dispatch<Action>]>(
  {} as any
)

const notificationTimes = [
  'oneDay',
  'oneHour',
  'gameTime',
  // 'test'
] as const
export type Notifications = {
  // oneDay: boolean
  // oneHour: boolean
  // gameTime: boolean
  [K in typeof notificationTimes[number]]: boolean
}

export type Settings = {
  activeJosef: JosefOptions
  autoplaySounds: 0 | 1
  deferredPwaPrompt: Event | undefined
  isEuroMode: boolean
  notifications: Notifications
}

//! Default settings and initial settings are different
//! to improve UX in cases where settings context has been
//! initialized but not updated from local storage yet.

const isEuroTimezone = getIsEuroTimezone()

//* default settings to set in Local Storage if none stored
//? no actual difference right now--keep anyway
const defaultSettings: Settings = {
  activeJosef: 'Looking',
  autoplaySounds: 0,
  deferredPwaPrompt: undefined,
  isEuroMode: isEuroTimezone ? true : false,
  notifications: {
    oneDay: false,
    oneHour: false,
    gameTime: false,
    // test: false,
  },
}

//* UX friendlier initial settings while Local Storage loads
//? no actual difference right now--keep anyway
const initialSettings: Settings = {
  activeJosef: 'Looking',
  autoplaySounds: 0,
  deferredPwaPrompt: undefined,
  isEuroMode: isEuroTimezone ? true : false,
  notifications: {
    oneDay: false,
    oneHour: false,
    gameTime: false,
    // test: false,
  },
}
