/*
  !Typing isn't working right using the stock useTheme hook, so this
  !is here to prevent from having to type every theme object gotten
  !from useTheme.
*/

import { useTheme as muiUseTheme } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

const useTheme = () => {
  const theme: Theme = muiUseTheme()

  return theme
}

export default useTheme
