import theme from 'src/gatsby-theme-material-ui-top-layout/theme'
import styled, { css } from 'styled-components'

export const dashedBorder = css`
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.8) 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  border-bottom: 0;
  border-collapse: separate;
  border-spacing: 0 1px;
`

export const spacing = (n: number) => `${theme.spacing(n)}px`

export const twoSpacing = `${theme.spacing(2)}px`
export const oneSpacing = `${theme.spacing(1)}px`

export const boldMono = css`
  font-family: 'Roboto Mono', monospace;
  font-weight: bold;
`

export const mdUpHide = css`
  display: table-cell;
  ${(p) => p.theme.breakpoints.up('md')} {
    display: none;
  }
`

export const mdDownHide = css`
  display: none;
  ${(p) => p.theme.breakpoints.up('md')} {
    display: table-cell;
  }
`

export const smDownHide = css`
  display: none;
  ${(p) => p.theme.breakpoints.up('sm')} {
    display: table-cell;
  }
`
export const smUpHide = css`
  display: table-cell;
  ${(p) => p.theme.breakpoints.up('sm')} {
    display: none;
  }
`
export const smMobileDownHide = css`
  display: none;
  @media (min-width: 400px) {
    display: table-cell;
  }
`
export const smMobileUpHide = css`
  display: table-cell;
  @media (min-width: 400px) {
    display: none;
  }
`
export const shrink = css`
  width: 0.1%;
  white-space: nowrap;
`

export const CellSpacer = styled.td`
  ${smMobileDownHide}
  && {
    min-width: 2ch;
    ${(p) => p.theme.breakpoints.up('sm')} {
      /* min-width: 5ch; */
    }
  }
`
