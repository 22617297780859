import { createMuiTheme, Theme } from '@material-ui/core/styles'
import { Palette } from '@material-ui/core/styles/createPalette'
import { isMobile, isFirefox } from 'react-device-detect'

//* Lame hack to kind of prevent body scrolling on desktop without hiding scrollbar
//* (for layout shifting purposes)
const preventScroll = (e: Event | KeyboardEvent) => {
  if (
    e.type === 'wheel' ||
    // (e.type === 'keydown' &&
    //   (e as KeyboardEvent).key !== 'Escape' &&
    //   (e as KeyboardEvent).key !== 'Tab')
    (e as KeyboardEvent).key === 'ArrowDown' ||
    (e as KeyboardEvent).key === 'ArrowUp' ||
    (e as KeyboardEvent).key === 'PageDown' ||
    (e as KeyboardEvent).key === 'PageUp'
  ) {
    e.preventDefault()
    e.stopPropagation()
  }
}
const addScrollPrevention = () => {
  if (!isMobile) {
    document.addEventListener('wheel', preventScroll, { passive: false })
    document.addEventListener('keydown', preventScroll, { passive: false })
  }
}
const removeScrollPrevention = () => {
  document.removeEventListener('wheel', preventScroll)
  document.removeEventListener('keydown', preventScroll)
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    lightGold: string
  }
  // allow configuration using `createMuiTheme`
  interface PaletteOptions {
    lightGold: string
  }
}

const width = 200
export const sidebarWidth = width

export const red = '#80000a'
export const gold = '#A19060'
export const black = '#221F1F'
export const paper = '#2B2929'
export const lightGold = '#E1DBCC'
// color(black)
//   .lighten(0.4)
//   .hex()

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    lightGold,
    primary: {
      main: gold,
    },
    secondary: {
      main: red,
    },
    background: {
      default: black,
      paper: paper,
    },
    error: {
      main: '#b50513',
    },
    warning: {
      // main: '#eb8b0e',
      main: '#ff8000',
      // main: '#e87a0c',
    },
    info: {
      // main: '#05427F',
      main: '#054589',
      // main: '#6854ff',
      // main: '#5457ff',
    },
  },
  typography: {
    fontFamily: ['Roboto Mono', 'monospace'].join(','),
    allVariants: {
      fontWeight: 'bold',
    },
  },
  props: {
    MuiModal: {
      //this doesn't have good enough 'onEvent' handling to do it all here
    },
    MuiDialog: {
      disableScrollLock: !isMobile,
      onEntering: addScrollPrevention,
      onExiting: removeScrollPrevention,
    },
    MuiPopover: {
      disableScrollLock: !isMobile,
      onEntering: addScrollPrevention,
      onExiting: removeScrollPrevention,
    },
    MuiPaper: {
      elevation: 8,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiBottomNavigation: {
      showLabels: false,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgb(97,97,97)',
      },
      arrow: {
        color: 'rgb(97,97,97)',
      },
    },
    MuiPaper: {
      root: {
        // backgroundColor: `${paper}cc`,
        // backdropFilter: 'blur(12px)',
      },
    },
    MuiDialog: {
      paper: {
        // backgroundColor: `${paper}bb`,
        backgroundColor: isFirefox ? `${black}e4` : `${black}bb`,
        backdropFilter: 'blur(12px)',
      },
    },
    // MuiDrawer: {
    //   paper: {
    //     width,
    //     // backgroundColor: `${black}bb`,
    //     backgroundColor: isFirefox ? `${black}eb` : `${black}bb`,
    //     backdropFilter: 'blur(12px)',
    //     '@media(min-width: 960px)': {
    //       backgroundColor: `${black}`,
    //       backdropFilter: 'none',
    //     },
    //     // '@supports(backdrop-filter: none)': {
    //     //   backgroundColor: `${black}bb`,
    //     //   backdropFilter: 'blur(12px)',
    //     // },
    //   },
    // },
    // MuiBottomNavigation: {
    //   root: {
    //     //TODO: fix this
    //     //!crappy short term hack
    //     backgroundColor: isFirefox ? `${black}eb` : `${black}bb`,
    //     // backgroundColor: `${black}bb`,
    //     backdropFilter: 'blur(12px)',
    //     zIndex: 1,
    //     // '@supports (backdrop-filter: none)': {
    //     //   backgroundColor: `${black}bb`,
    //     //   backdropFilter: 'blur(12px)',
    //     // },
    //   },
    // },
    // MuiBottomNavigationAction: {
    //   root: {
    //     // paddingLeft: 0,
    //     // paddingRight: 0,
    //     color: red,
    //     '&$selected': {
    //       color: gold,
    //       // fontSize: '0.80rem',
    //     },
    //     // '&$label': {
    //     //   '&$selected': {
    //     //     fontSize: '0.80rem',
    //     //   },
    //     // },
    //   },
    //   label: {
    //     '&$selected': {
    //       fontSize: '0.80rem',
    //     },
    //   },
    //   // selected: {
    //   //   fontSize: '0.80rem',
    //   //   color: gold,
    //   // },
    //   // label: {
    //   //   fontSize: '0.80rem',
    //   // },
    // },
  },
})

export default theme
