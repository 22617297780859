import { useContext } from 'react'
// import { useMotionValue, useAnimation } from 'framer-motion'
import { isBrowser, isMobile } from 'react-device-detect'
import { LayoutContext } from 'src/features/layout'
import useTheme from 'src/common/hooks/use-theme'
import { sidebarWidth } from 'src/gatsby-theme-material-ui-top-layout/theme'

type Desktop = {
  dodge?: 'none' | 'dodge'
  alignment?: 'center' | 'left'
  cssProp?: 'padding' | 'margin'
  // addWidthInCssUnits?: string
  addWidthNumPx?: number
  cssTransitionProps?: string | string[]
  variantAddons?: {
    open: {
      [key: string]: number | string
    }
    close: {
      [key: string]: number | string
    }
  }
}
type Mobile = {
  dodge?: 'none' | 'dodge'
  cssProp?: 'padding' | 'margin'
  addHeightInCssUnits?: string
}
/**
 * Hook to get elements to avoid navbars when needed
 *
 * @param desktop persistent sidebar offset options, applies horizontally
 * @param mobile bottom nav offset options, applies vertically
 */
const useMotionNavOffsetStyle = (desktop?: Desktop, mobile?: Mobile) => {
  const theme = useTheme()
  const { sidebarState, isAppMounted } = useContext(LayoutContext)

  if (isBrowser) {
    const {
      dodge = 'dodge',
      alignment = 'left',
      cssProp = 'margin',
      addWidthNumPx = 0,
      // cssTransitionProps,
      variantAddons = { open: {}, close: {} },
    } = desktop ?? {}

    if (dodge === 'none') return {}

    type Transition = {
      type: string
      duration: number
      ease: number[]
    }
    type Variants = {
      open: {
        transition: Transition
        //TODO: should be number | string
        [key: string]: any
      }
      close: {
        transition: Transition
        //TODO: should be number | string
        [key: string]: any
      }
    }
    const variants: Variants = {
      open: {
        [`${cssProp}Left`]:
          sidebarWidth / (alignment === 'center' ? 2 : 1) + addWidthNumPx,
        ...variantAddons.open,
        transition: {
          type: 'tween',
          duration: theme.transitions.duration.enteringScreen / 1000,
          ease: getEasing(theme.transitions.easing.easeOut),
        },
      },
      close: {
        [`${cssProp}Left`]: 0 + addWidthNumPx,
        ...variantAddons.close,
        transition: {
          type: 'tween',
          duration: theme.transitions.duration.leavingScreen / 1000,
          ease: getEasing(theme.transitions.easing.sharp),
        },
      },
    }

    // const cssProps = Array.isArray(cssTransitionProps)
    //   ? [`${cssProp}Left`, ...cssTransitionProps]
    //   : cssTransitionProps !== undefined
    //   ? [`${cssProp}Left`, cssTransitionProps]
    //   : [`${cssProp}Left`]

    // cssProps.forEach((prop) => {
    //   variants.open[prop] =
    //     sidebarWidth / (alignment === 'center' ? 2 : 1) + addWidthNumPx
    //   variants.close[prop] = addWidthNumPx
    // })

    const props = {
      initial: false,
      animate:
        sidebarState.isPersistent && sidebarState.isOpen ? 'open' : 'close',
      variants,
    }

    return props
  }

  //? No need for transitions for mobile (for now)
  if (isMobile) {
    //I *think* this should help mobile styles work correctly
    if (!isAppMounted) return {}

    const { dodge = 'dodge', cssProp = 'margin', addHeightInCssUnits = '0px' } =
      mobile ?? {}

    if (dodge === 'none') return {}

    //default MUI size in px
    const bottomNavHeight = 56
    const offset = `calc(${bottomNavHeight}px + ${addHeightInCssUnits})`

    // const props = {
    //   style:
    // }
    const props = {
      style: {
        [`${cssProp}Bottom`]: offset,
      },
    }

    return props
  }

  //I'm not sure in what circumstance isBrowser and isMobile will be false, but...
  return {}
}

export default useMotionNavOffsetStyle

const getEasing = (easeStr: string) =>
  Array.from(easeStr.match(/(\d|\.)+/g) ?? []).map((num) => parseFloat(num))
