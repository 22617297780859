import { Match } from 'src/common/types'
import React, { useContext } from 'react'
import color from 'color'
import { motion } from 'framer-motion'
import dateFormat from 'dateformat'
import styled from 'styled-components'
import { getClubInfo } from 'src/common/clubs'
import { SettingsContext } from '../settings/settings-context'
import AbbrevScore from '../common/abbrev-score'
import { rounded } from 'src/common/styles'
import useTheme from 'src/common/hooks/use-theme'

type MatchLabel = {
  match: Match
  isSelected?: boolean
}
const NavLabel = ({ match, isSelected }: MatchLabel) => {
  const theme = useTheme()
  const [{ isEuroMode }] = useContext(SettingsContext)

  const { result, homeTeam, awayTeam } = match
  const homeAbbrev = getClubInfo(homeTeam).abbrev
  const awayAbbrev = getClubInfo(awayTeam).abbrev

  //I don't *think* this component can exist with result === null
  const { homeResult, awayResult, homeScore, awayScore } = result!

  const dateFormatStr = isEuroMode ? 'd/m' : 'm/d'

  const transition = { duration: 0.12 }

  //! keep this for now
  // const selectedColor = color(theme.palette.secondary.main)
  //   .darken(0.2)
  //   .hex()
  //calculated from above
  // const selectedColor = '#660008'
  // const selectedColor = theme.palette.secondary.light
  // const nonSelectedColor = theme.palette.primary.main
  // const selectedColor = theme.palette.primary.main
  // const nonSelectedColor = 'rgba(255, 255, 255, 0.55)'
  // const selectedColor = theme.palette.primary.main
  // const nonSelectedColor = theme.palette.secondary.dark
  // const selectedColor = `${theme.palette.lightGold}cc`
  // const nonSelectedColor = theme.palette.primary.main
  const nonSelectedColor = `${theme.palette.lightGold}bb`
  const selectedColor = theme.palette.primary.main

  const containerVariants = {
    hover: {
      filter: 'brightness(1.2)',
    },
    animate: {
      filter: 'brightness(1)',
    },
  }

  return (
    <Container
      transition={transition}
      whileHover="hover"
      animate="animate"
      initial={false}
      variants={containerVariants}
      style={{
        border: `2px solid ${isSelected ? selectedColor : nonSelectedColor}`,
      }}
    >
      <DateText
        style={{
          backgroundColor: `${isSelected ? selectedColor : nonSelectedColor}`,
          // color: isSelected ? theme.palette.primary.main : undefined,
        }}
      >
        {dateFormat(match.date, dateFormatStr)}
      </DateText>
      <ScoreText>
        <AbbrevScore
          abbrev={homeAbbrev}
          score={homeScore}
          isWinner={homeResult === 'w'}
          winnerColor={color(theme.palette.secondary.light)
            .lighten(0.2)
            .hex()}
          // winnerColor={theme.palette.primary.light}
        />
      </ScoreText>
      <ScoreText>
        <AbbrevScore
          abbrev={awayAbbrev}
          score={awayScore}
          isWinner={awayResult === 'w'}
          // winnerColor={theme.palette.secondary.light}
          winnerColor={color(theme.palette.secondary.light)
            .lighten(0.2)
            .hex()}
          // winnerColor={theme.palette.primary.light}
        />
      </ScoreText>
    </Container>
  )
}

export default NavLabel

const Container = styled(motion.div)`
  width: 100%;
  line-height: 1.2;
  padding-bottom: 2px;
  background-color: ${(p) => p.theme.palette.background.paper};
  box-shadow: ${(p) => p.theme.shadows[8]};
  /* color: ${(p) => p.theme.palette.lightGold}cc; */
  /* color: ${(p) => p.theme.palette.primary.main}; */
  color: ${(p) =>
    color(p.theme.palette.primary.light)
      .lighten(0.1)
      .hex()};
  ${rounded}
`
const DateText = styled.div`
  color: ${(p) => p.theme.palette.background.default};
  background-color: ${(p) => p.theme.palette.primary.main};
  margin-bottom: 4px;
`
const ScoreText = styled.div`
  width: fit-content;
  margin: auto;
`
