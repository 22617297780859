import React, { useContext } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import { NotificationTime } from './notification-settings'
import { SettingsContext } from '../settings-context'
import { HandleShowErrMsg } from '.'

type NotificationSwitchProps = {
  notificationTime: NotificationTime
  text: string
  checkNotificationPermissions(): Promise<boolean>
  handleShowErrMsg: HandleShowErrMsg
}
const NotificationCheckbox = ({
  notificationTime,
  text,
  checkNotificationPermissions,
  handleShowErrMsg,
}: NotificationSwitchProps) => {
  const [settings, dispatch] = useContext(SettingsContext)
  const isChecked = settings.notifications[notificationTime]

  const handleClick = async () => {
    const arePermissionsBlocked = await checkNotificationPermissions()

    dispatch({
      type: 'updateNotifications',
      payload: {
        notificationTime,
        isChecked: !isChecked,
        arePermissionsBlocked,
        handleShowErrMsg,
      },
    })
  }

  return (
    <ListItem
      button
      onClick={handleClick}
      // style={{ paddingLeft: 0 }}
    >
      <ListItemIcon>
        <Checkbox edge="start" checked={isChecked} />
      </ListItemIcon>

      <ListItemText>{text}</ListItemText>
    </ListItem>
  )
}

export default NotificationCheckbox
