import { navigate } from 'gatsby'
import { WindowLocation } from '@reach/router'
import useMatchData from './use-match-data'

//! Currently, this is really just for navbar navigation

const useNavigate = () => {
  const matches = useMatchData()
  return (path: string, location?: WindowLocation) => {
    const split = location?.pathname.split('/')
    const isResultPage = split?.[1] === 'results' && split?.[2]

    const currentPath = location?.pathname.replace(/\//g, '')

    if (currentPath !== path && !(path === 'results' && isResultPage)) {
      navigate(
        `/${path}`
        // {
        //   state: isResultPage
        //     ? {
        //         date: matches.find((match) =>
        //           match.date.includes(split?.[2] ?? "I'm bad at typescript")
        //         )?.date,
        //       }
        //     : undefined,
        // }
      )
    }
  }
}

export default useNavigate
