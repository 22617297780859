import React, { createContext, useState, useEffect } from 'react'
import { SetState } from 'src/common/types'
import useNextMatchData from 'src/common/hooks/use-next-match-data'
import { getTimePieces } from 'src/common/utils'

//TODO: merge this with useNextMatchData hook

type IsLiveContextProviderProps = {
  children: React.ReactNode
}
const LiveProvider = ({ children }: IsLiveContextProviderProps) => {
  const { matchDate, timeOnMount, areNoFutureMatches } = useNextMatchData()
  // const matchDate = new Date('2020-07-11T16:45:00')
  const [isLive, setIsLive] = useState(false)
  const [isMatchDay, setIsMatchDay] = useState(
    timeOnMount.getMonth() === matchDate?.getMonth() &&
      timeOnMount.getDate() === matchDate?.getDate()
  )

  const checkIsMatchDay = () => {
    const now = new Date()
    const isMatchDay =
      now.getMonth() === matchDate?.getMonth() &&
      now.getDate() === matchDate?.getDate()

    setIsMatchDay(isMatchDay)
  }

  const checkIsLive = () => {
    if (matchDate === undefined) {
      setIsLive(false)
      return
    }
    const { timeDelta } = getTimePieces(false, matchDate)
    setIsLive(timeDelta < 0)
  }

  useEffect(() => {
    checkIsLive()
    checkIsMatchDay()
    const interval = setInterval(() => {
      checkIsLive()
      checkIsMatchDay()
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDate])

  return (
    <IsLiveContext.Provider
      value={{ isLive, setIsLive, checkIsLive, isMatchDay, areNoFutureMatches }}
    >
      {children}
    </IsLiveContext.Provider>
  )
}

export default LiveProvider

type IsLiveContext = {
  isLive: boolean
  setIsLive: SetState<boolean>
  checkIsLive(): void
  isMatchDay: boolean
  areNoFutureMatches: boolean
}
export const IsLiveContext = createContext<IsLiveContext>({} as IsLiveContext)
