import React from 'react'
// import { motion } from 'framer-motion'
import styled from 'styled-components'

type FabContainerProps = {
  style?: React.CSSProperties
  children?: React.ReactNode
  //TODO: fix type
  // //framer motion props
  // props?: any
}
const FabContainer = ({ style, children }: FabContainerProps) => {
  return <Container style={style}>{children}</Container>
}

export default FabContainer

export const fabSizePx = 88

const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: ${(p) => p.theme.spacing(2)}px;
  z-index: 2;
`
