import React from 'react'
import styled from 'styled-components'
import useTheme from 'src/common/hooks/use-theme'

type AbbrevScoreProps = {
  abbrev: string
  score: string
  winnerColor?: string
  isWinner?: boolean
  isLeft?: boolean
  className?: string
}
const AbbrevScore = ({
  abbrev,
  score,
  isLeft,
  winnerColor,
  isWinner,
  className,
}: AbbrevScoreProps) => {
  const theme = useTheme()
  const color = winnerColor ?? theme.palette.secondary.main
  return (
    <Container {...{ isLeft, color, isWinner, className }}>
      {isLeft ? score : abbrev} {isLeft ? abbrev : score}
    </Container>
  )
}

export default AbbrevScore

type ContainerProps = {
  color: string
  isWinner?: boolean
  isLeft?: boolean
}
const Container = styled.span<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  /* margin: 0 16px; */
  color: ${(p) => (p.isWinner ? p.color : 'inherit')};
  white-space: nowrap;
  ::before {
    position: absolute;
    font-family: 'ESPN-Icon-Font';
    /* Great Jorb! */
    /* margin-top: 0.05em; */
    font-size: 0.6em;
    right: ${(p) => (p.isLeft ? 'initial' : '-1.1ch')};
    left: ${(p) => (p.isLeft ? '-1.1ch' : 'initial')};
    content: ${(p) =>
      p.isWinner ? (p.isLeft ? "'\\E088'" : "'\\E087'") : undefined};
  }
`
