import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '@material-ui/core/Icon'

import { WindowLocation } from '@reach/router'

type SidebarItemProps = {
  text: string
  path: string
  icon: JSX.Element
  onClick(path: string): () => void
  location?: WindowLocation
}
const SidebarItem = ({
  text,
  path,
  icon,
  onClick,
  location,
}: SidebarItemProps) => {
  return (
    <MenuItem
      onClick={onClick(path)}
      selected={location?.pathname.split('/')[1] === path}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </MenuItem>
  )
}

export default SidebarItem
