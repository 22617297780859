// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-injuries-tsx": () => import("./../../src/pages/injuries.tsx" /* webpackChunkName: "component---src-pages-injuries-tsx" */),
  "component---src-pages-libs-tsx": () => import("./../../src/pages/libs.tsx" /* webpackChunkName: "component---src-pages-libs-tsx" */),
  "component---src-pages-mib-tsx": () => import("./../../src/pages/mib.tsx" /* webpackChunkName: "component---src-pages-mib-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-roster-tsx": () => import("./../../src/pages/roster.tsx" /* webpackChunkName: "component---src-pages-roster-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-standings-tsx": () => import("./../../src/pages/standings.tsx" /* webpackChunkName: "component---src-pages-standings-tsx" */),
  "component---src-pages-trainhorn-tsx": () => import("./../../src/pages/trainhorn.tsx" /* webpackChunkName: "component---src-pages-trainhorn-tsx" */),
  "component---src-pages-valentine-tsx": () => import("./../../src/pages/valentine.tsx" /* webpackChunkName: "component---src-pages-valentine-tsx" */),
  "component---src-templates-static-summary-tsx": () => import("./../../src/templates/static-summary.tsx" /* webpackChunkName: "component---src-templates-static-summary-tsx" */)
}

