import React from 'react'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'

type SettingsSublistProps = {
  children: React.ReactNode
}
const SettingsSublist = ({ children }: SettingsSublistProps) => {
  return (
    <SublistWrapper>
      <SublistLine orientation="vertical" flexItem />
      <List disablePadding dense style={{ flexGrow: 1 }}>
        {children}
      </List>
    </SublistWrapper>
  )
}

export default SettingsSublist

const SublistWrapper = styled.div`
  display: flex;
`

const SublistLine = styled(Divider)`
  margin-left: ${(p) => p.theme.spacing(9)}px;
`
