import firebase from 'gatsby-plugin-firebase'
import { getFCMToken } from 'src/features/firebase/utils'
import { NotificationTime } from './notifications/notification-settings'
import { HandleShowErrMsg } from './notifications'

export type UpdateSubscription = {
  notificationTime: NotificationTime
  isChecked: boolean
  arePermissionsBlocked: boolean
  handleShowErrMsg: HandleShowErrMsg
}
type UpdateSubscriptionReturn = {
  wasSuccessful: boolean
  errMsg?: string
}
//return wasSuccessful and possibly error
export const updateSubscription = async ({
  notificationTime,
  isChecked,
}: UpdateSubscription): Promise<UpdateSubscriptionReturn> => {
  //set the region
  const functions = firebase.app().functions('us-east1')

  // //enable for local functions development
  // if (process.env.NODE_ENV === 'development') {
  //   functions.useFunctionsEmulator('http://localhost:5001')
  // }

  const addSubscription = functions.httpsCallable('addSubscription')
  const removeSubscription = functions.httpsCallable('removeSubscription')

  try {
    const token = await getFCMToken()
    const response = isChecked
      ? await addSubscription({ token, topic: notificationTime })
      : await removeSubscription({ token, topic: notificationTime })
    console.log(response)
  } catch (err) {
    console.log(err.message)
    console.log(err)
    return { wasSuccessful: false, errMsg: err.message }
  }
  return { wasSuccessful: true }
}
