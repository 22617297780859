import React, { useContext } from 'react'
import NotificationCheckbox from './notification-checkbox'
import { SettingsContext } from '../settings-context'
import { HandleShowErrMsg } from '.'

export const notificationTimes = [
  'oneDay',
  'oneHour',
  'gameTime',
  // 'test',
] as const
export type NotificationTime = typeof notificationTimes[number]

type NotificationSettingsProps = {
  checkNotificationPermissions(): Promise<boolean>
  handleShowErrMsg: HandleShowErrMsg
}
const NotificationSettings = ({
  checkNotificationPermissions,
  handleShowErrMsg,
}: NotificationSettingsProps) => {
  const [{ isEuroMode }] = useContext(SettingsContext)

  return (
    <>
      <NotificationCheckbox
        notificationTime="oneDay"
        text="1 day before"
        checkNotificationPermissions={checkNotificationPermissions}
        handleShowErrMsg={handleShowErrMsg}
      />
      <NotificationCheckbox
        notificationTime="oneHour"
        text="1 hour before"
        checkNotificationPermissions={checkNotificationPermissions}
        handleShowErrMsg={handleShowErrMsg}
      />
      <NotificationCheckbox
        notificationTime="gameTime"
        text={`At ${isEuroMode ? 'match' : 'game'} time`}
        checkNotificationPermissions={checkNotificationPermissions}
        handleShowErrMsg={handleShowErrMsg}
      />
      {/* <NotificationCheckbox
        notificationTime="test"
        text="developer test"
        checkNotificationPermissions={checkNotificationPermissions}
        handleShowErrMsg={handleShowErrMsg}
      /> */}
    </>
  )
}

export default NotificationSettings
