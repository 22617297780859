import styled, { css } from 'styled-components'
import { fabSizePx } from './components/fab-container'
import { Link } from 'gatsby'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'
import theme from 'src/gatsby-theme-material-ui-top-layout/theme'

export const spacing = (n: number) => `${theme.spacing(n)}px`

/*
 * Mixins
 */

//* Positioning
export const avoidFabMargin = css`
  margin-bottom: ${fabSizePx}px;
`
export const avoidFabPadding = css`
  padding-bottom: ${fabSizePx}px;
`
export const absCenter = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const absCenterX = css`
  left: 50%;
  transform: translateX(-50%);
`
export const fillContainer = css`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const standardMargins = css`
  /* margin: 0; */
  margin: ${spacing(1)} 0;
  @media (min-width: 375px) {
    margin: ${spacing(1)};
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${spacing(2)};
  }
`

//* Backgrounds
export const glassyDefaultBG = css`
  background-color: ${(p) => p.theme.palette.background.default}f8;
  @supports (backdrop-filter: none) {
    background-color: ${(p) => p.theme.palette.background.default}cc;
    backdrop-filter: blur(12px);
  }
`
export const standardCardBg = css`
  background-color: ${(p) => p.theme.palette.background.paper}cc;
`

//* Other
export const rounded = css`
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
`

/*
 * Functions
 */

type DodgeFabOptions = {
  cssProp?: 'padding' | 'margin'
  contentWidth?: number
}
export const dodgeFab = (options?: DodgeFabOptions) => {
  const { cssProp = 'padding', contentWidth = 99999 } = options ?? {}
  if (cssProp === 'margin') {
    return css`
      @media (max-width: ${contentWidth + fabSizePx * 2}px) {
        margin-bottom: ${fabSizePx}px;
      }
    `
  }
  return css`
    @media (max-width: ${contentWidth + fabSizePx * 2}px) {
      padding-bottom: ${fabSizePx}px;
    }
  `
}

/*
 * Components
 */

export const GridList = styled(List)`
  display: grid;
  max-width: ${(p) => p.theme.breakpoints.values.md + p.theme.spacing(2)}px;
  margin: auto;
  grid-template-columns: ${(p) =>
    `repeat(auto-fill, minmax(${320 - p.theme.spacing(1) * 2}px, 1fr))`};
  grid-auto-rows: 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  padding-left: ${(p) => p.theme.spacing(1)}px;
  padding-right: ${(p) => p.theme.spacing(1)}px;
  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
    padding-left: ${(p) => p.theme.spacing(2)}px;
    padding-right: ${(p) => p.theme.spacing(2)}px;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    grid-gap: ${(p) => p.theme.spacing(2)}px;
  }
`

export const Card = styled(Paper)`
  background-color: ${(p) => p.theme.palette.background.paper}cc;
  margin: 0;
  /* padding: 0; */
  padding: ${spacing(1)};
  @media (min-width: 375px) {
    margin: ${spacing(1)};
    padding: ${spacing(1)};
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${spacing(2)};
    padding: ${spacing(2)};
  }
`
export const StyledLink = styled(Link)`
  text-transform: uppercase;
  color: ${(p) => p.theme.palette.primary.main};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`
export const FaIcon = styled(Icon)`
  overflow: visible;
  display: inline-flex;
`
