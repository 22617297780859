import React, { useContext } from 'react'
import { BrowserView } from 'react-device-detect'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import { SettingsContext } from 'src/features/settings/settings-context'

const Autoplay = () => {
  const [{ autoplaySounds }, dispatch] = useContext(SettingsContext)

  return (
    <BrowserView>
      <ListItem>
        <ListItemIcon>
          <VolumeUpIcon />
        </ListItemIcon>
        <ListItemText primary="Autoplay sounds" />
        <Switch
          edge="end"
          checked={autoplaySounds === 1}
          onChange={() =>
            dispatch({
              type: 'updateSettings',
              payload: {
                autoplaySounds: autoplaySounds === 1 ? 0 : 1,
              },
            })
          }
        />
      </ListItem>
    </BrowserView>
  )
}

export default Autoplay
