import React, { useContext } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import DevicesIcon from '@material-ui/icons/Devices'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { SettingsContext } from 'src/features/settings/settings-context'

const PwaInstall = () => {
  const [{ deferredPwaPrompt }, dispatch] = useContext(SettingsContext)
  const isInstalled = useMediaQuery('(display-mode: standalone)')

  const installPwa = () => {
    //@ts-ignore ...perhaps I typed this incorrectly?
    deferredPwaPrompt?.prompt()

    dispatch({
      type: 'updateSettings',
      payload: { deferredPwaPrompt: undefined },
    })
  }

  if (deferredPwaPrompt && !isInstalled) {
    return (
      <ListItem>
        <ListItemIcon>
          <DevicesIcon />
        </ListItemIcon>
        <ListItemText>
          <Button
            onClick={installPwa}
            variant="contained"
            color="secondary"
            startIcon={<GetAppIcon />}
          >
            install as local app
          </Button>
        </ListItemText>
      </ListItem>
    )
  }

  return null
}

export default PwaInstall
