import React, { useState } from 'react'
import { isIOS } from 'react-device-detect'
import styled from 'styled-components'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useFullscreen from 'src/common/hooks/use-fullscreen'
import usePathSettings from 'src/common/hooks/use-path-settings'
import { WindowLocation } from '@reach/router'
import useNavigate from 'src/common/hooks/use-navigate'

type BottomNavProps = {
  location?: WindowLocation
}
const BottomNav = ({ location }: BottomNavProps) => {
  const pathSettings = usePathSettings()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<Element>()
  const {
    FullscreenIcon,
    toggleFullscreen,
    isFullscreenEnabled,
  } = useFullscreen()
  const isNotSmall = useMediaQuery('(min-width: 400px)')
  const navigate = useNavigate()

  const handleMenuNavigate = (path: string) => () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen)
    navigate(path, location)
  }

  const handleChange = (e: React.ChangeEvent<Element>, path: string) => {
    if (path === 'more') {
      setAnchorEl(e.target)
      setIsMenuOpen((isMenuOpen) => !isMenuOpen)
    } else {
      isMenuOpen && setIsMenuOpen(false)
      navigate(path, location)
    }
  }

  // const path = location?.pathname.replace(/\//g, '')
  const splitPath = location?.pathname.split('/')[1]
  const numOfButtons = isNotSmall ? 4 : 3

  // eslint-disable-next-line array-callback-return
  const mainButtons = pathSettings.map((setting, i) => {
    if (i < numOfButtons) {
      const { name, icon, path } = setting

      return (
        <BottomNavigationAction
          label={name}
          value={path}
          icon={icon}
          key={name}
        />
      )
    }
  })

  const moreMenu = pathSettings.map((setting, i) => {
    if (i >= numOfButtons) {
      const { name, icon, path } = setting

      return (
        <MenuItem
          selected={splitPath === path}
          onClick={handleMenuNavigate(path)}
          key={name}
        >
          <ListItemIcon>
            <ListItemIcon>{icon}</ListItemIcon>
          </ListItemIcon>
          <ListItemText primary={name} />
        </MenuItem>
      )
    } else {
      return undefined
    }
  })

  //displayed nav value is either a main button or 'more' for 'more' routes
  const value =
    pathSettings
      .slice(numOfButtons)
      .filter((setting) =>
        setting.path.includes(splitPath ?? 'this is for type purposes')
      ).length === 1
      ? 'more'
      : splitPath

  return (
    <>
      {/*TODO: solve the mystery of this typing... */}
      <StyledNav value={value} onChange={handleChange as any}>
        {mainButtons}
        <BottomNavigationAction
          label="More"
          value="more"
          icon={<MoreHorizIcon />}
        />
      </StyledNav>
      <Menu
        open={isMenuOpen}
        id="more-menu"
        anchorEl={anchorEl}
        onClose={() => setIsMenuOpen(false)}
        //TODO: do this better
        style={{ zIndex: 9999999, transform: 'translateY(-40px)' }}
        keepMounted
      >
        {moreMenu}
        {isFullscreenEnabled && (
          //span is to avoid MUI console error about fragments...ugh
          <span>
            <Divider
              variant="middle"
              //Note: component prop is incompatible with styled-components
              component="li"
              // style={{ marginTop: '8px', marginBottom: '8px' }}
            />
            <MenuItem onClick={toggleFullscreen}>
              <ListItemIcon>{React.createElement(FullscreenIcon)}</ListItemIcon>
              <ListItemText primary={'Fullscreen'} />
            </MenuItem>
          </span>
        )}
      </Menu>
    </>
  )
}

export default BottomNav

const StyledNav = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${(p) => p.theme.palette.background.default}eb;
  /* TODO: decide if this is good */
  /* padding-bottom: ${isIOS ? '16px' : '0'}; */
  /* height: ${isIOS ? '72px' : '56px'}; */
  z-index: 1;
  @supports (backdrop-filter: none) {
    background-color: ${(p) => p.theme.palette.background.default}bb;
    backdrop-filter: blur(12px);
  }
  button {
    color: ${(p) => p.theme.palette.secondary.main};
  }
  .Mui-selected {
    color: ${(p) => p.theme.palette.primary.main};
  }
  span.Mui-selected {
    font-size: 0.75rem;
  }
`
