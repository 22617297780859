import React, { useContext } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'
import LanguageIcon from '@material-ui/icons/Language'
import { SettingsContext } from 'src/features/settings/settings-context'
import { getIsEuroTimezone } from '../utils'

const EuroMode = () => {
  const [{ isEuroMode }, dispatch] = useContext(SettingsContext)

  return (
    <ListItem>
      <ListItemIcon>
        <LanguageIcon />
      </ListItemIcon>
      <ListItemText
        /*"Eurosnob Mode" is not a dig at Europeans, but a silly joke in reference
          to a certain type of highly-opinionated N. American soccer fan*/
        primary={getIsEuroTimezone() ? 'Europe Mode' : 'Eurosnob Mode™'}
      />
      <Switch
        edge="end"
        checked={isEuroMode}
        onChange={() =>
          dispatch({
            type: 'toggleSetting',
            payload: 'isEuroMode',
          })
        }
      />
    </ListItem>
  )
}

export default EuroMode
