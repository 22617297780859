import { WindowLocation } from '@reach/router'
import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import useMatchData from 'src/common/hooks/use-match-data'
import { spacing } from '../standings/styles'
import NavLabel from './nav-label'
import { rounded } from 'src/common/styles'

type TopBarProps = {
  location: WindowLocation
}
const ResultNav = ({ location }: TopBarProps) => {
  const matches = useMatchData()
  const completedMatches = matches.filter((match) => match.completed)
  const pathname =
    location.pathname.split('/')[2] ||
    completedMatches[completedMatches.length - 1].date.split('T')[0]
  const [tabValue, setTabValue] = useState<string>(pathname)
  const props = useMotionNavOffsetStyle({}, { dodge: 'none' })

  const handleChange = (e: React.ChangeEvent<{}>, newValue: string) => {
    navigate(`/results/${newValue}`)
    setTabValue(newValue)
  }

  useEffect(() => {
    //TODO: I can't remember if this conditional is necessary
    if (tabValue !== pathname) {
      setTabValue(pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Container {...props}>
      <Bar>
        <MatchTabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {completedMatches.map((match) => {
            const date = match.date.split('T')[0]
            return (
              <MatchTab
                value={date}
                label={
                  <MatchInnerTab>
                    <NavLabel {...{ match }} isSelected={date === tabValue} />
                  </MatchInnerTab>
                }
                key={match.date}
              />
            )
          })}
        </MatchTabs>
      </Bar>
    </Container>
  )
}

export default ResultNav

const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
`
const Bar = styled.div`
  margin: ${spacing(1)};
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  /* color: ${(p) => p.theme.palette.primary.main}; */
  overflow: hidden;
  ${rounded}
  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${spacing(2)};
    margin-bottom: 0;
  }
`
const MatchTabs = styled(Tabs)`
  width: fit-content;
  .MuiTabs-scroller {
    border: 2px solid ${(p) => p.theme.palette.primary.main}55;
    ${rounded}
  }
  .MuiTabs-indicator {
    display: none;
  }
  button:first-of-type {
    border-left: none;
  }
`
const MatchInnerTab = styled.div`
  width: fit-content;
  width: 100%;
  padding: 4px;
`
const MatchTab = styled(Tab)`
  padding: 0;
  /* color: ${(p) => p.theme.palette.primary.main}; */
  width: 84px;
  min-width: 84px; /* override default MUI style */
  &.MuiTab-textColorInherit {
    opacity: 1;
  }
`
