import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Link from '@material-ui/core/Link'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import EmailIcon from '@material-ui/icons/Email'
import RedditIcon from '@material-ui/icons/Reddit'
import SettingsSublist from '../settings-sublist'

const Contact = () => {
  const [eCon, setECon] = useState<string>()

  useEffect(() => {
    setECon(generateECon())
  }, [])

  type Contact = {
    text: string
    url: string
    icon: React.ReactElement
  }
  const contacts: Contact[] = [
    {
      text: eCon ?? 'missing...',
      url: `mailto:${eCon}`,
      icon: <EmailIcon />,
    },
    {
      text: 'APPUTD',
      url: `https://www.reddit.com/user/apputd`,
      icon: <RedditIcon />,
    },
  ]

  const contactComponents = contacts.map((contact) => (
    <ListItem key={contact.url}>
      <ListItemIcon>{contact.icon}</ListItemIcon>
      <ListItemText>
        <Link href={contact.url} target="_blank">
          <ContactText>{contact.text}</ContactText>
        </Link>
      </ListItemText>
    </ListItem>
  ))

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <ContactSupportIcon />
        </ListItemIcon>
        <ListItemText>Feedback</ListItemText>
      </ListItem>

      <SettingsSublist>{contactComponents}</SettingsSublist>
    </>
  )
}

export default Contact

const generateECon = () => {
  const firstEpart = ['f', 'e', 'e', 'd', 'b', 'a', 'c', 'k']
  const secondEpart = ['@', 'a', 'p', 'p', 'u', 't', 'd', '.', 'c', 'o', 'm']

  return firstEpart.join('') + secondEpart.join('')
}

const ContactText = styled.span`
  @media (max-width: 361px) {
    font-size: 0.85em;
  }
`
