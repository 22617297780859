import { useStaticQuery, graphql } from 'gatsby'
import { MatchDataQuery } from '../../../types/graphql-types'
import { Match } from 'src/features/countdown'

/*
  TODO:
  ---
    * try to get types fixed
    * see if queries can be combined at build time
*/

const useMatchData = () => {
  const query = useStaticQuery<MatchDataQuery>(graphql`
    query MatchData {
      allMatches(sort: { fields: [date], order: ASC }) {
        nodes {
          awayTeam
          broadcast
          competitionType
          completed
          date
          detailedBroadcast {
            tv
            streaming
          }
          homeTeam
          id
          isHome
          location
          opponent
          result {
            atlResult
            atlScore
            awayResult
            awayScore
            homeResult
            homeScore
            oppResult
            oppScore
            text
            winner
          }
        }
      }
      # allResults {
      #   nodes {
      #     header {
      #       competitions {
      #         status {
      #           type {
      #             completed
      #           }
      #         }
      #       }
      #     }
      #     date
      #   }
      # }
    }
  `)

  //not sure how I feel about the typing, but oh well
  const matchData = query.allMatches.nodes as Match[]
  // const completedData = query.allResults.nodes // as any[]

  // const composedData = matchData.map((match) => {
  //   const i = completedData.findIndex((node) => node.date === match.date)
  //   if (i > -1) {
  //     return {
  //       ...match,
  //       completed: !!completedData?.[i]?.header?.competitions?.[0]?.status?.type
  //         ?.completed,
  //     }
  //   }
  //   return { ...match, completed: false }
  // })

  return matchData
}

export default useMatchData
