import firebase from 'gatsby-plugin-firebase'

export const initMessaging = () => {
  try {
    const messaging = firebase.messaging()

    // Callback fired if Instance ID token is updated.
    messaging.onTokenRefresh(async () => {
      const refreshedToken = await messaging.getToken()

      /*
        TODO:
        ---
          ! Put logic here to update subscriptions
          ! I have also never seen this happen yet
      */
      console.log('Token refreshed: ', refreshedToken)
    })

    //this is where callback for receiving messages in browser
    //(aka not in background) would go if you wanted to do that
    messaging.onMessage((message) => {
      console.log(message)

      navigator.serviceWorker
        .getRegistration('/firebase-cloud-messaging-push-scope')
        .then((registration) => {
          registration?.showNotification(
            message.notification.title,
            message.notification
          )
        })
    })
  } catch (err) {
    console.error(err)
  }
}

export const getFCMToken = async () => {
  await Notification.requestPermission()
  const token = await firebase.messaging().getToken()
  return token
}
