/*
  ? This context exists to save post-build, newer dynamic news articles
  ? loaded from firestore server after user visits hybrid static/dynamic
  ? news page so that they don't have to be read from db every page load.

  ? Articles are only updated every 30 minutes on backend, so it isn't
  ? likely they will miss new data, and this saves on db reads and improves UX
 */
import React, { createContext, useState } from 'react'
import { FeedItem } from './types'
import { SetState } from 'src/common/types'

type NewNews = { feedItems: FeedItem[]; prevUpdate: Date } | undefined
type NewNewsProviderProps = {
  children: React.ReactNode
}
const NewNewsProvider = ({ children }: NewNewsProviderProps) => {
  const [newNews, setNewNews] = useState<NewNews>()

  return (
    <NewNewsContext.Provider value={[newNews, setNewNews]}>
      {children}
    </NewNewsContext.Provider>
  )
}

export default NewNewsProvider

type NewNewsContext = [NewNews, SetState<NewNews>]
export const NewNewsContext = createContext<NewNewsContext>(
  {} as NewNewsContext
)
