import { useStaticQuery, graphql } from 'gatsby'

const useSiteData = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  type SiteMetadata = {
    title: string
    description: string
    author: string
  }
  const { title, description, author } = site.siteMetadata as SiteMetadata

  return { title, description, author }
}

export default useSiteData
