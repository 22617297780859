import React from 'react'
import List from '@material-ui/core/List'
import EuroMode from './settings-content/euro-mode'
import JosefBackground from './settings-content/josef-background'
import Contact from './settings-content/contact'
import Notifications from './notifications'
import Autoplay from './settings-content/autoplay'
import PwaInstall from './settings-content/pwa-install'

const SettingsContent = () => {
  return (
    <>
      <List disablePadding>
        <JosefBackground />
        <EuroMode />
        <Autoplay />
        <Notifications />
        <Contact />
        <PwaInstall />
      </List>
    </>
  )
}

export default React.memo(SettingsContent)
