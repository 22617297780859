import { IconBaseProps } from 'react-icons/lib/cjs'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { SettingsContext } from 'src/features/settings/settings-context'
import Icon from '@material-ui/core/Icon'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import EventNoteIcon from '@material-ui/icons/EventNote'
import SettingsIcon from '@material-ui/icons/Settings'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import FavoriteIcon from '@material-ui/icons/Favorite'
import RestoreIcon from '@material-ui/icons/Restore'
import { SvgIconProps } from '@material-ui/core'
import { FaNewspaper, FaBriefcaseMedical } from 'react-icons/fa'
import { WiTrain } from 'react-icons/wi'
import { FaAngry } from 'react-icons/fa'
import BugReportIcon from '@material-ui/icons/BugReport'
// import { useTypedSelector } from 'src/features/redux/reducers'
import { IsLiveContext } from 'src/features/live/live-context'

type MuiIcon = (props: SvgIconProps) => JSX.Element
type ReactIcon = (props: IconBaseProps) => JSX.Element
export type PathIcon = MuiIcon | ReactIcon
export type PathIconProps = { style?: React.CSSProperties; [key: string]: any }
type PathSetting = {
  path: string
  name: string
  icon: JSX.Element
}
//! The order of these determines order in nav menus, and in
//! the case of mobile, which "main buttons" are shown as well.
//! I.e., put these in order of importance.
const usePathSettings = (): PathSetting[] => {
  const { isLive, areNoFutureMatches } = useContext(IsLiveContext)
  const [{ isEuroMode }] = useContext(SettingsContext)

  const valentine: PathSetting = {
    path: 'valentine',
    name: 'Valentine',
    icon: <FavoriteIcon />,
  }

  const pathSettings: PathSetting[] = [
    {
      path: '',
      name: isLive ? 'Live' : areNoFutureMatches ? 'Countup' : 'Countdown',
      icon: isLive ? <LiveTvIcon /> : <TimelapseIcon />,
    },
    {
      path: 'schedule',
      name: isEuroMode ? 'Fixtures' : 'Schedule',
      icon: <EventNoteIcon />,
    },
    {
      path: 'news',
      name: 'News',
      icon: (
        <FaIcon>
          <FaNewspaper />
        </FaIcon>
      ),
    },
    {
      path: 'standings',
      name: isEuroMode ? 'Table' : 'Standings',
      icon: <EmojiEventsIcon />,
    },
    {
      path: 'results',
      name: 'Results',
      icon: <RestoreIcon />,
    },
    {
      path: 'injuries',
      name: 'Injuries',
      icon: (
        <FaIcon>
          <FaBriefcaseMedical />
        </FaIcon>
      ),
    },
    {
      path: 'roster',
      name: isEuroMode ? 'Squad' : 'Roster',
      icon: <PeopleAltIcon />,
    },
    {
      path: 'trainhorn',
      name: 'Train\xa0Horn',
      icon: (
        <TrainIcon>
          <WiTrain />
        </TrainIcon>
      ),
    },
    {
      path: 'libs',
      name: 'Josef\xa0Libs',
      icon: (
        <FaIcon>
          <FaAngry />
        </FaIcon>
      ),
    },
    {
      path: 'mib',
      name: 'MLS\xa0is\xa0Back',
      icon: <BugReportIcon />,
    },
    {
      path: 'settings',
      name: 'Settings',
      icon: <SettingsIcon />,
    },
  ]

  //add the valentine nav link only on Valentine's Day
  const today = new Date()
  if (today.getMonth() === 1 && today.getDate() === 14) {
    pathSettings.splice(1, 0, valentine)
  }

  return pathSettings
}

export default usePathSettings

const FaIcon = styled(Icon)`
  overflow: visible;
  display: inline-flex;
`
const TrainIcon = styled(FaIcon)`
  transform: translate(-2.5px, 0);
  font-size: 1.9rem;
`
