import { Settings, BoolSetState } from './settings-context'
import { UpdateSubscription, updateSubscription } from './settings-actions'
import {
  NotificationTime,
  notificationTimes,
} from './notifications/notification-settings'

/*
  TODO:
  ---
    * Really rethink where/how the debouncing stuff exists
 */

export type Action =
  | {
      type: 'updateSettings'
      payload: Partial<Settings>
    }
  | {
      type: 'toggleSetting'
      payload: keyof Settings
    }
  | {
      type: 'updateNotifications'
      // payload: {
      //   notificationTime: NotificationTime
      //   isChecked: boolean
      //   arePermissionsBlocked: boolean
      //   handleShowErrMsg: (errReason: string) => void
      // }
      payload: UpdateSubscription
    }
  | { type: 'updateFromLocalstorage'; payload: Settings }

export const reducer: React.Reducer<Settings, Action> = (
  settings: Settings,
  action: Action
) => {
  switch (action.type) {
    case 'updateSettings': {
      const updatedState = { ...settings, ...action.payload }
      localStorage.setItem('settings', JSON.stringify(updatedState))
      return updatedState
    }
    case 'toggleSetting': {
      const updatedState = {
        ...settings,
        [action.payload]: !settings[action.payload],
      }
      localStorage.setItem('settings', JSON.stringify(updatedState))
      return updatedState
    }
    case 'updateNotifications': {
      const {
        notificationTime,
        isChecked,
        arePermissionsBlocked,
      } = action.payload

      const updatedState = {
        ...settings,
        notifications: {
          ...settings.notifications,
          [notificationTime]: isChecked,
        },
      }
      if (arePermissionsBlocked) {
        notificationTimes.forEach(
          (time) => (updatedState.notifications[time] = false)
        )
      }
      localStorage.setItem('settings', JSON.stringify(updatedState))
      return updatedState
    }
    case 'updateFromLocalstorage': {
      return action.payload
    }
    default:
      return settings
  }
}

type Timers = {
  [T in NotificationTime]: {
    timeout?: number
    startingState?: boolean
    isTimedOut: boolean
  }
}
const timers: Timers = {
  oneDay: {
    isTimedOut: false,
  },
  oneHour: {
    isTimedOut: false,
  },
  gameTime: {
    isTimedOut: false,
  },
  // test: {
  //   isTimedOut: false,
  // },
}
const debounceUpdate = (
  notificationTime: NotificationTime,
  payload: UpdateSubscription,
  dispatch: React.Dispatch<Action>
) => {
  const timer = timers[notificationTime]
  clearTimeout(timer.timeout)
  //TODO: only using isTimedOut to initialize startingState...
  timer.startingState = timer.isTimedOut
    ? timer.startingState
    : !payload.isChecked
  timer.isTimedOut = true
  timer.timeout = setTimeout(async () => {
    //? This could be dangerous
    //? ...only update if the final checked state changed
    if (payload.isChecked === timer.startingState) return

    const { handleShowErrMsg } = payload
    const { wasSuccessful, errMsg } = await updateSubscription(payload)
    //! this is presently redundant from previous check
    const arePermissionsBlocked = errMsg?.includes('blocked') === true

    !wasSuccessful && handleShowErrMsg(errMsg)

    // const isChecked: boolean = wasSuccessful
    //   ? payload.isChecked
    //   : arePermissionsBlocked
    //   ? false
    //   : timer.startingState!

    const isChecked: boolean = wasSuccessful
      ? payload.isChecked
      : timer.startingState!

    if (wasSuccessful) {
      timer.startingState = payload.isChecked
    } else {
      dispatch({
        type: 'updateNotifications',
        payload: {
          isChecked,
          notificationTime,
          arePermissionsBlocked,
          handleShowErrMsg,
        },
      })
    }
  }, 1000)
}

export const dispatchMiddleware = (dispatch: React.Dispatch<Action>) => async (
  action: Action
) => {
  switch (action.type) {
    case 'updateNotifications': {
      dispatch(action)
      const { notificationTime } = action.payload
      action.payload.arePermissionsBlocked ||
        debounceUpdate(notificationTime, action.payload, dispatch)
      break
    }

    default:
      return dispatch(action)
  }
}
