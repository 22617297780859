import React, {
  useState,
  createContext,
  SetStateAction,
  Dispatch,
  useEffect,
  useRef,
} from 'react'
import {
  BrowserView,
  MobileView,
  isMobile,
  isBrowser,
} from 'react-device-detect'
//@ts-ignore
// import PWAPrompt from 'react-ios-pwa-prompt'
import useTheme from 'src/common/hooks/use-theme'
import { WindowLocation } from '@reach/router'
import { sidebarWidth } from 'src/gatsby-theme-material-ui-top-layout/theme'
//@ts-ignore This is how the Gatsby docs recommend using static files
import trainHornAudio from 'src/features/train-horn-button/trainhorn.mp3'
import Sidebar from '../sidebar'
import SettingsDialog from '../settings/settings-dialog'
import BottomNav from 'src/features/bottom-nav'
import HamburgerButton from '../sidebar/hamburger-button'
import NewNewsProvider from 'src/features/news/new-news-context'
import ResultNav from '../result-nav'
import LiveProvider from '../live/live-context'
//@ts-ignore
import loadable from '@loadable/component'

//@ts-ignore
const PWAPrompt = loadable(() => import('react-ios-pwa-prompt'))

export type SidebarState = {
  isOpen: boolean
  isPersistent: boolean
  isTransitioning: boolean
  width: number
}

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const hookTheme = useTheme()
  const { md, lg } = hookTheme.breakpoints.values
  const mdQueryStr = `(min-width:${md}px)`
  const mdQuery =
    typeof window !== 'undefined' ? matchMedia(mdQueryStr) : undefined
  const lgQueryStr = `(min-width:${lg}px)`
  const lgQuery =
    typeof window !== 'undefined' ? matchMedia(lgQueryStr) : undefined
  const [location, setLocation] = useState<WindowLocation>()
  const [isAppMounted, setIsAppMounted] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [sidebarState, setSidebarState] = useState({
    isOpen: isMobile || lgQuery?.matches || false,
    isPersistent: isMobile || mdQuery?.matches || false,
    isTransitioning: false,
    // isTransitioning: true,
    width: isBrowser && lgQuery?.matches ? sidebarWidth : 0,
  })

  if (typeof window !== 'undefined') {
    const hasClearedCache = localStorage.getItem('hasClearedCache')
    if (hasClearedCache !== 'true') {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name)
          console.log(`deleted cache item ${name}`)
        })
        localStorage.setItem('hasClearedCache', 'true')
        window.location.reload()
      })
    }
  }

  useEffect(() => {
    setIsAppMounted(true)
  }, [])

  //mdQuery
  useEffect(() => {
    const callback = () => {
      if (isMobile) return
      setSidebarState((prevState) => ({
        ...prevState,
        width: mdQuery?.matches && prevState.isOpen ? sidebarWidth : 0,
        isPersistent: mdQuery?.matches || false,
        isOpen: (mdQuery?.matches && prevState.isOpen) || false,
      }))
    }
    try {
      // Chrome & Firefox
      mdQuery?.addEventListener('change', callback)
    } catch (e1) {
      try {
        // Safari
        //@ts-ignore
        mdQuery?.addListener('change', callback)
      } catch (e2) {
        console.error(e2)
      }
    }

    //TODO: figure out Safari stuff
    return () => {
      mdQuery?.removeEventListener('change', callback)
      mdQuery?.removeListener(callback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //lgQuery
  useEffect(() => {
    const callback = () => {
      if (isMobile) return
      toggleSidebar(lgQuery?.matches)
    }
    // lgQuery?.addEventListener('change', callback)
    try {
      // Chrome & Firefox
      lgQuery?.addEventListener('change', callback)
    } catch (e1) {
      try {
        // Safari
        //@ts-ignore
        lgQuery?.addListener('change', callback)
      } catch (e2) {
        console.error(e2)
      }
    }

    return () => {
      lgQuery?.removeEventListener('change', callback)
      lgQuery?.removeListener(callback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleSidebar = (isOpen?: boolean) => {
    setSidebarState((prevState) => {
      const updatedOpen = isOpen !== undefined ? isOpen : !prevState.isOpen
      return {
        ...prevState,
        isOpen: updatedOpen,
        // isTransitioning: mdQuery?.matches ? true : false,
        width: updatedOpen && mdQuery?.matches ? sidebarWidth : 0,
      }

      //!! More likely, remove this!!
      // //!! Update this !!
      // if (!prevState.isTransitioning) {
      //   // if (true) {
      //   // isTransitioning state only used with persistent drawer
      //   mdQuery?.matches &&
      //     setTimeout(
      //       () =>
      //         setSidebarState((sidebarState) => ({
      //           ...sidebarState,
      //           isTransitioning: false,
      //         })),
      //       225
      //     )

      //   return {
      //     ...prevState,
      //     isOpen: updatedOpen,
      //     isTransitioning: mdQuery?.matches ? true : false,
      //     width: updatedOpen && mdQuery?.matches ? sidebarWidth : 0,
      //   }
      // } else {
      //   return prevState
      // }
    })
  }

  const playTrainHorn = () => {
    const horn = audioRef.current
    if (horn !== null) {
      horn.currentTime = 0
      horn.play()
    }
  }

  // const isResultPage = () => {
  //   const split = location?.pathname.split('/')
  //   if (split?.[1] === 'schedule' && split?.[2]) {
  //     return true
  //   }
  //   return false
  // }

  return (
    <>
      <LayoutContext.Provider
        value={{
          location,
          setLocation,
          sidebarState,
          playTrainHorn,
          isAppMounted,
        }}
      >
        <LiveProvider>
          {location && location?.pathname.includes('results') && (
            <ResultNav {...{ location }} />
          )}
          <NewNewsProvider>{children}</NewNewsProvider>

          <audio ref={audioRef} src={trainHornAudio} preload="auto" />

          {isAppMounted && (
            <>
              <PWAPrompt
                // copyTitle="Install as Local App"
                // promptOnVisit={2}
                delay={5000}
                // debug
              />
              <BrowserView>
                <Sidebar
                  sidebarState={sidebarState}
                  toggleSidebar={toggleSidebar}
                  setIsSettingsOpen={setIsSettingsOpen}
                  location={location}
                />
                <HamburgerButton
                  isSidebarOpen={sidebarState.isOpen}
                  toggleSidebar={toggleSidebar}
                />
                <SettingsDialog
                  isSettingsOpen={isSettingsOpen}
                  setIsSettingsOpen={setIsSettingsOpen}
                />
                {/* <BottomNav location={location} /> */}
              </BrowserView>

              <MobileView>
                <BottomNav location={location} />
                {/* <Sidebar
                  sidebarState={sidebarState}
                  toggleSidebar={toggleSidebar}
                  setIsSettingsOpen={setIsSettingsOpen}
                  location={location}
                />
                <HamburgerButton toggleSidebar={toggleSidebar} />
                <SettingsDialog
                  isSettingsOpen={isSettingsOpen}
                  setIsSettingsOpen={setIsSettingsOpen}
                /> */}
              </MobileView>
            </>
          )}
        </LiveProvider>
      </LayoutContext.Provider>
    </>
  )
}

export default Layout

type LayoutContextValue = {
  location?: WindowLocation
  setLocation: Dispatch<SetStateAction<WindowLocation | undefined>>
  sidebarState: SidebarState
  playTrainHorn(): void
  isAppMounted: boolean
}
export const LayoutContext = createContext({} as LayoutContextValue)
