import { useStaticQuery, graphql } from 'gatsby'
import { PreviewJosefsQuery } from 'types/graphql-types'
import { JosefOptions } from '../countdown/game-day-josef/use-josef'

const usePreviewJosefs = (whichJosef: JosefOptions) => {
  const { allFile } = useStaticQuery<PreviewJosefsQuery>(graphql`
    query PreviewJosefs {
      allFile(
        filter: {
          relativeDirectory: { eq: "josefs/pre-game" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fixed(width: 70, quality: 1) {
              originalName
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `)

  const josefIndex = whichJosef
    ? allFile.nodes.findIndex(
        (josef) =>
          josef.childImageSharp?.fixed?.originalName === `${whichJosef}.png`
      )
    : 0

  const josefs: JosefOptions[] = []
  allFile.nodes.forEach((node) =>
    josefs.push(
      node.childImageSharp?.fixed?.originalName?.replace(
        '.png',
        ''
      ) as JosefOptions
    )
  )
  josefs.sort()

  return {
    josefFluidData: allFile.nodes[josefIndex].childImageSharp?.fixed,
    josefs,
  }
}

export default usePreviewJosefs
