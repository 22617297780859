import useMatchData from 'src/common/hooks/use-match-data'
import { Match } from '../types'

//TODO: merge this with live context

const useNextMatchData = () => {
  const matches = useMatchData()
  const timeOnMount = new Date()

  //?This works because this isn't pulling from DB, but from static data from
  //?last build. I.e., this will show last match until after site is rebuilt,
  //?which is the desired behavior.
  const nextMatch: Match | undefined = matches.find((match) => !match.completed)
  // const nextMatch: Match | undefined = matches[matches.length - 1]

  const areNoFutureMatches = !nextMatch
  const prevMatch = areNoFutureMatches
    ? matches[matches.length - 1]
    : matches[matches.indexOf(nextMatch!) - 1]
  const prevMatchDate = new Date(prevMatch.date)

  const matchDate = nextMatch && new Date(nextMatch.date)
  // const matchDate = nextMatch
  //   ? new Date(nextMatch.date)
  //   : new Date(matches[matches.length - 1].date)
  // const matchDate = new Date('2020-07-28T20:00:00')

  //ATL vs NYRB
  // const matchDate = new Date('2020-07-11T20:45:00')

  const midnightBeforeMatch = matchDate && new Date(matchDate)
  midnightBeforeMatch?.setHours(0)
  const midnightTimeDelta: number | undefined =
    matchDate &&
    midnightBeforeMatch &&
    matchDate.valueOf() - midnightBeforeMatch.valueOf()

  return {
    midnightTimeDelta,
    matchDate,
    nextMatch,
    timeOnMount,
    areNoFutureMatches,
    prevMatch,
    prevMatchDate,
  }
}

export default useNextMatchData
