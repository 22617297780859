/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import * as Sentry from '@sentry/browser'
// import firebase from 'gatsby-plugin-firebase'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/messaging'
import 'firebase/auth'
import { ThemeProvider } from 'styled-components'
import SettingsProvider from 'src/features/settings/settings-context'
import theme from 'src/gatsby-theme-material-ui-top-layout/theme'
import './src/styles/global.css'
import { initMessaging } from './src/features/firebase/utils'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
  })
}

//? Offline persistence isn't doing anything now
// firebase
//   .firestore()
//   .enablePersistence()
//   .then(
//     () =>
//       process.env.NODE_ENV === 'development' &&
//       console.log('it seems like offline persistence is working for firestore')
//   )
//   .catch((reason) => console.warn(reason))

export const onClientEntry = () => {
  initMessaging()
}

//I don't love this, but this should solve stale-data problem for now
export const onServiceWorkerUpdateReady = () => {
  console.log('service worker update ready')
  window.location.reload()
}

export const wrapRootElement = ({ element }) => (
  <>
    <ThemeProvider theme={theme}>
      <SettingsProvider>{element}</SettingsProvider>
    </ThemeProvider>
  </>
)
