import { useState, useEffect, useCallback } from 'react'
import MUIFullscreenIcon from '@material-ui/icons/Fullscreen'
import MUIFullscreenExitIcon from '@material-ui/icons/FullscreenExit'

const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isFullscreenEnabled, setIsFullscreenEnabled] = useState(false)

  //TODO: clean this up
  const toggleFullscreen = () => {
    isFullscreen
      ? document
          .exitFullscreen()
          .then(() => setIsFullscreen(false))
          .catch((err) => {
            console.error(err)
          })
      : document.documentElement
          .requestFullscreen()
          .then(() => setIsFullscreen(true))
          .catch((err) => {
            console.error(err)
          })
  }

  useEffect(() => {
    document.fullscreenEnabled && setIsFullscreenEnabled(true)
  }, [])

  //This effect is to try and keep the state in sync with browser if uncontrolled
  //fullscreen exiting occurs (i.e. not through javascript API)
  useEffect(() => {
    const fullscreenEventFunc = () => {
      if (document.fullscreenElement === null && isFullscreen) {
        setIsFullscreen(false)
      }
    }
    document.addEventListener('fullscreenchange', fullscreenEventFunc)

    return () =>
      document.removeEventListener('fullscreenchange', fullscreenEventFunc)
  }, [isFullscreen])

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      /*
        Only want to handle F11 when *not* fullscreen because:
          a. preventDefault doesn't work in fullscreen anyway with Chrome, and
          b. the useEffect hook above eliminates the need to control F11 if
             already in fullscreen
      
      */
      if (e.key === 'F11' && !isFullscreen) {
        e.preventDefault()
        toggleFullscreen()
      }
    }
    document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  }, [isFullscreen])

  const FullscreenIcon = isFullscreen
    ? MUIFullscreenExitIcon
    : MUIFullscreenIcon

  return { toggleFullscreen, isFullscreenEnabled, isFullscreen, FullscreenIcon }
}

export default useFullscreen
