import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Draggable from 'react-draggable'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { BoolSetState } from 'src/features/settings/settings-context'
import SettingsContent from './settings-content'

type SettingsDialogProps = {
  isSettingsOpen: boolean
  setIsSettingsOpen: BoolSetState
}
const SettingsDialog = ({
  isSettingsOpen,
  setIsSettingsOpen,
}: SettingsDialogProps) => {
  const handleClose = () => {
    setIsSettingsOpen(false)
  }

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
      open={isSettingsOpen}
      PaperComponent={PaperComponent}
      onClose={handleClose}
    >
      <DialogTitle>Settings</DialogTitle>
      <SettingsDialogContent>
        <SettingsContent />
      </SettingsDialogContent>
      <DialogActions>
        <Button onClick={handleClose}>close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SettingsDialog

const SettingsDialogContent = styled(DialogContent)`
  padding: 0;
`
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      // bounds="parent"
      cancel={
        '.MuiSwitch-root, .MuiButtonBase-root, .MuiSelect-root, .MuiLink-root, .josef-preview'
      }
    >
      <Paper style={{ cursor: 'move' }} {...props} />
    </Draggable>
  )
}
