import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import useFullscreen from 'src/common/hooks/use-fullscreen'
import { SidebarState } from '../layout'

type FullscreenButtonProps = {
  toggleSidebar(isOpen?: boolean): void
}

const FullscreenButton = ({ toggleSidebar }: FullscreenButtonProps) => {
  const {
    isFullscreenEnabled,
    isFullscreen,
    toggleFullscreen,
    FullscreenIcon,
  } = useFullscreen()

  const handleClick = () => {
    isFullscreen || toggleSidebar(false)
    toggleFullscreen()
  }

  return (
    <>
      {isFullscreenEnabled && (
        <Tooltip title="Fullscreen Mode">
          <Button onClick={handleClick}>
            <FullscreenIcon color="secondary" />
          </Button>
        </Tooltip>
      )}
    </>
  )
}

export default FullscreenButton

const Button = styled(IconButton)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${(p) => p.theme.zIndex.drawer + 101};
`
