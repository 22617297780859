import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { AppLogoQuery } from 'types/graphql-types'

type AppLogoSidebarProps = {
  //
}
const AppLogoSidebar = ({}: AppLogoSidebarProps) => {
  const logoFluidData = useLogoFluidData()

  return (
    // <Wrapper>
    <Img fixed={logoFluidData as any} />
    // </Wrapper>
  )
}

export default AppLogoSidebar

const Wrapper = styled.div`
  /* width: 100%; */
  /* margin-right: ${(p) => p.theme.spacing(1)}px; */
`
const useLogoFluidData = () => {
  const { allFile } = useStaticQuery<AppLogoQuery>(graphql`
    query AppLogo {
      allFile(
        filter: {
          relativePath: { eq: "icon.png" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fixed(width: 30, quality: 1) {
              originalName
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `)

  return allFile?.nodes[0]?.childImageSharp?.fixed
}
