type ClubInfo = {
  displayName: string
  aliases: string[]
  abbrev: string
}

export const getClubInfo = (clubName: string): ClubInfo => {
  const uppercaseClubName = clubName.toUpperCase().replace(/\(.+\)/, '')
  const clubIndex = clubInfos.findIndex((clubInfo) =>
    clubInfo.aliases.includes(uppercaseClubName)
  )

  if (clubIndex > -1) {
    return clubInfos[clubIndex]
  }
  return {
    displayName: clubName,
    aliases: [uppercaseClubName],
    abbrev: uppercaseClubName.slice(0, 3),
  }
}

const clubInfos: ClubInfo[] = [
  {
    displayName: 'Atlanta United FC',
    aliases: ['ATLANTA UNITED FC', 'ATLANTA UNITED', 'ATL'],
    abbrev: 'ATL',
  },
  {
    displayName: 'Birmingham Legion',
    aliases: ['BIRMINGHAM LEGION', 'BIR'],
    abbrev: 'BIR',
  },
  {
    displayName: 'Chicago Fire FC',
    aliases: ['CHICAGO FIRE FC', 'CHICAGO FIRE', 'CHI'],
    abbrev: 'CHI',
  },
  {
    displayName: 'Club America',
    aliases: ['CLUB AMERICA', 'AMÉRICA', 'AME'],
    abbrev: 'AME',
  },
  {
    displayName: 'Colorado Rapids',
    aliases: ['COLORADO RAPIDS', 'COL'],
    abbrev: 'COL',
  },
  {
    displayName: 'Columbus Crew SC',
    aliases: ['COLUMBUS CREW SC', 'COLUMBUS CREW', 'CLB'],
    abbrev: 'CLB',
  },
  {
    displayName: 'D.C. United',
    aliases: ['D.C. UNITED', 'DC UNITED', 'DC'],
    abbrev: 'DC',
  },
  {
    displayName: 'FC Cincinnati',
    aliases: ['FC CINCINNATI', 'CINCINNATI', 'CIN'],
    abbrev: 'CIN',
  },
  {
    displayName: 'FC Dallas',
    aliases: ['FC DALLAS', 'DALLAS', 'DAL'],
    abbrev: 'DAL',
  },
  {
    displayName: 'Houston Dynamo',
    aliases: ['HOUSTON DYNAMO', 'HOU'],
    abbrev: 'HOU',
  },
  {
    displayName: 'IF Elfsborg',
    aliases: ['IF ELFSBORG', 'IFE'],
    abbrev: 'IFE',
  },
  {
    displayName: 'Inter Miami CF',
    aliases: ['INTER MIAMI CF', 'INTER MIAMI', 'MIA'],
    abbrev: 'MIA',
  },
  {
    displayName: 'LA Galaxy',
    aliases: ['LA GALAXY', 'LA', 'LAG'],
    abbrev: 'LA',
  },
  {
    displayName: 'Leones Negros',
    aliases: ['LEONES NEGROS', 'LN'],
    abbrev: 'LN',
  },
  {
    displayName: 'Los Angeles Football Club',
    aliases: ['LOS ANGELES FOOTBALL CLUB', 'LOS ANGELES', 'LAFC', 'LFC'],
    abbrev: 'LFC',
  },
  {
    displayName: 'Minnesota United FC',
    aliases: ['MINNESOTA UNITED FC', 'MINNESOTA UNITED', 'MIN'],
    abbrev: 'MIN',
  },
  {
    displayName: 'Montreal Impact',
    aliases: ['MONTREAL IMPACT', 'MTL'],
    abbrev: 'MTL',
  },
  {
    displayName: 'Motagua',
    aliases: ['MOTAGUA', 'CD MOTAGUA', 'MOT'],
    abbrev: 'MOT',
  },
  {
    displayName: 'Nashville SC',
    aliases: ['NASHVILLE SC', 'NSH'],
    abbrev: 'NSH',
  },
  {
    displayName: 'New England Revolution',
    aliases: ['NEW ENGLAND REVOLUTION', 'NEW ENGLAND', 'NE'],
    abbrev: 'NE',
  },
  {
    displayName: 'New York City FC',
    aliases: ['NEW YORK CITY FC', 'NEW YORK CITY', 'NYC', 'NYCFC'],
    abbrev: 'NYC',
  },
  {
    displayName: 'New York Red Bulls',
    aliases: [
      'NEW YORK RED BULLS',
      'NY RED BULLS',
      'NEW YORK RB',
      'RBNY',
      //I'm worried about this one
      'NY',
      'NYRB',
    ],
    abbrev: 'NY',
  },
  {
    displayName: 'Orlando City SC',
    aliases: ['ORLANDO CITY SC', 'ORLANDO CITY', 'ORL'],
    abbrev: 'ORL',
  },
  {
    displayName: 'Philadelphia Union',
    aliases: ['PHILADELPHIA UNION', 'PHI'],
    abbrev: 'PHI',
  },
  {
    displayName: 'Portland Timbers',
    aliases: ['PORTLAND TIMBERS', 'POR'],
    abbrev: 'POR',
  },
  {
    displayName: 'Real Salt Lake',
    aliases: ['REAL SALT LAKE', 'RSL'],
    abbrev: 'RSL',
  },
  {
    displayName: 'San Jose Earthquakes',
    aliases: ['SAN JOSE EARTHQUAKES', 'SJ EARTHQUAKES', 'SJ'],
    abbrev: 'SJ',
  },
  {
    displayName: 'Seattle Sounders FC',
    aliases: ['SEATTLE SOUNDERS FC', 'SEATTLE SOUNDERS', 'SEA'],
    abbrev: 'SEA',
  },
  {
    displayName: 'Sporting Kansas City',
    aliases: ['SPORTING KANSAS CITY', 'SPORTING KC', 'SKC'],
    abbrev: 'SKC',
  },
  {
    displayName: 'Toronto FC',
    aliases: ['TORONTO FC', 'TORONTO', 'TOR'],
    abbrev: 'TOR',
  },
  {
    displayName: 'Vancouver Whitecaps FC',
    aliases: ['VANCOUVER WHITECAPS FC', 'VANCOUVER WHITECAPS', 'VAN'],
    abbrev: 'VAN',
  },
]

// type ClubInfo = {
//   displayName: string
//   aliases: string[]
//   abbrev: string
// }

// export const getClubInfo = (clubName: string): ClubInfo => {
//   const uppercaseClubName = clubName.toUpperCase().replace(/\(.+\)/, '')
//   const clubIndex = clubInfos.findIndex((clubInfo) =>
//     clubInfo.aliases.includes(uppercaseClubName)
//   )

//   if (clubIndex > -1) {
//     return clubInfos[clubIndex]
//   }
//   return {
//     displayName: clubName,
//     aliases: [uppercaseClubName],
//     abbrev: uppercaseClubName.slice(0, 3),
//   }
// }

// const clubInfos: ClubInfo[] = [
//   {
//     displayName: 'Atlanta United FC',
//     aliases: ['ATLANTA UNITED FC', 'ATLANTA UNITED'],
//     abbrev: 'ATL',
//   },
//   {
//     displayName: 'Birmingham Legion',
//     aliases: ['BIRMINGHAM LEGION'],
//     abbrev: 'BIR',
//   },
//   {
//     displayName: 'Chicago Fire FC',
//     aliases: ['CHICAGO FIRE FC', 'CHICAGO FIRE'],
//     abbrev: 'CHI',
//   },
//   {
//     displayName: 'Club America',
//     aliases: ['CLUB AMERICA', 'AMÉRICA'],
//     abbrev: 'AME',
//   },
//   {
//     displayName: 'Colorado Rapids',
//     aliases: ['COLORADO RAPIDS'],
//     abbrev: 'COL',
//   },
//   {
//     displayName: 'Columbus Crew SC',
//     aliases: ['COLUMBUS CREW SC', 'COLUMBUS CREW'],
//     abbrev: 'CLB',
//   },
//   {
//     displayName: 'D.C. United',
//     aliases: ['D.C. UNITED', 'DC UNITED'],
//     abbrev: 'DC',
//   },
//   {
//     displayName: 'FC Cincinnati',
//     aliases: ['FC CINCINNATI', 'CINCINNATI'],
//     abbrev: 'CIN',
//   },
//   {
//     displayName: 'FC Dallas',
//     aliases: ['FC DALLAS', 'DALLAS'],
//     abbrev: 'DAL',
//   },
//   {
//     displayName: 'Houston Dynamo',
//     aliases: ['HOUSTON DYNAMO'],
//     abbrev: 'HOU',
//   },
//   {
//     displayName: 'IF Elfsborg',
//     aliases: ['IF ELFSBORG'],
//     abbrev: 'IFE',
//   },
//   {
//     displayName: 'Inter Miami CF',
//     aliases: ['INTER MIAMI CF', 'INTER MIAMI'],
//     abbrev: 'MIA',
//   },
//   {
//     displayName: 'LA Galaxy',
//     aliases: ['LA GALAXY'],
//     abbrev: 'LA',
//   },
//   {
//     displayName: 'Leones Negros',
//     aliases: ['LEONES NEGROS'],
//     abbrev: 'LN',
//   },
//   {
//     displayName: 'Los Angeles Football Club',
//     aliases: ['LOS ANGELES FOOTBALL CLUB', 'LOS ANGELES'],
//     abbrev: 'LFC',
//   },
//   {
//     displayName: 'Minnesota United FC',
//     aliases: ['MINNESOTA UNITED FC', 'MINNESOTA UNITED'],
//     abbrev: 'MIN',
//   },
//   {
//     displayName: 'Montreal Impact',
//     aliases: ['MONTREAL IMPACT'],
//     abbrev: 'MTL',
//   },
//   {
//     displayName: 'Motagua',
//     aliases: ['MOTAGUA', 'CD MOTAGUA'],
//     abbrev: 'MOT',
//   },
//   {
//     displayName: 'Nashville SC',
//     aliases: ['NASHVILLE SC'],
//     abbrev: 'NSH',
//   },
//   {
//     displayName: 'New England Revolution',
//     aliases: ['NEW ENGLAND REVOLUTION', 'NEW ENGLAND'],
//     abbrev: 'NE',
//   },
//   {
//     displayName: 'New York City FC',
//     aliases: ['NEW YORK CITY FC', 'NEW YORK CITY'],
//     abbrev: 'NYC',
//   },
//   {
//     displayName: 'New York Red Bulls',
//     aliases: ['NEW YORK RED BULLS', 'NY RED BULLS', 'NEW YORK RB'],
//     abbrev: 'NY',
//   },
//   {
//     displayName: 'Orlando City SC',
//     aliases: ['ORLANDO CITY SC', 'ORLANDO CITY'],
//     abbrev: 'ORL',
//   },
//   {
//     displayName: 'Philadelphia Union',
//     aliases: ['PHILADELPHIA UNION'],
//     abbrev: 'PHI',
//   },
//   {
//     displayName: 'Portland Timbers',
//     aliases: ['PORTLAND TIMBERS'],
//     abbrev: 'POR',
//   },
//   {
//     displayName: 'Real Salt Lake',
//     aliases: ['REAL SALT LAKE'],
//     abbrev: 'RSL',
//   },
//   {
//     displayName: 'San Jose Earthquakes',
//     aliases: ['SAN JOSE EARTHQUAKES', 'SJ EARTHQUAKES'],
//     abbrev: 'SJ',
//   },
//   {
//     displayName: 'Seattle Sounders FC',
//     aliases: ['SEATTLE SOUNDERS FC', 'SEATTLE SOUNDERS'],
//     abbrev: 'SEA',
//   },
//   {
//     displayName: 'Sporting Kansas City',
//     aliases: ['SPORTING KANSAS CITY', 'SPORTING KC'],
//     abbrev: 'SKC',
//   },
//   {
//     displayName: 'Toronto FC',
//     aliases: ['TORONTO FC', 'TORONTO'],
//     abbrev: 'TOR',
//   },
//   {
//     displayName: 'Vancouver Whitecaps FC',
//     aliases: ['VANCOUVER WHITECAPS FC', 'VANCOUVER WHITECAPS'],
//     abbrev: 'VAN',
//   },
// ]
