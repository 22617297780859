import React from 'react'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import { WindowLocation } from '@reach/router'
import { BoolSetState } from 'src/features/settings/settings-context'
import usePathSettings from 'src/common/hooks/use-path-settings'
import useNavigate from 'src/common/hooks/use-navigate'
import SidebarItem from './sidebar-item'
import { SidebarState } from '../layout'
import FullscreenButton from './fullscreen-button'
import useSiteData from 'src/common/hooks/use-site-data'
import AppLogoSidebar from './app-logo-sidebar'

type SidebarProps = {
  sidebarState: SidebarState
  toggleSidebar(isOpen?: boolean): void
  setIsSettingsOpen: BoolSetState
  location?: WindowLocation
}
const Sidebar = ({
  sidebarState,
  toggleSidebar,
  setIsSettingsOpen,
  location,
}: SidebarProps) => {
  const pathSettings = usePathSettings()
  const { title } = useSiteData()
  const navigate = useNavigate()
  const { isOpen, isPersistent } = sidebarState

  const handleNavigate = (path: string) => () => {
    isPersistent || toggleSidebar(false)
    if (path === 'settings') {
      setIsSettingsOpen(true)
    } else {
      navigate(path, location)
    }
  }

  const handleSettingsClick = () => {
    setIsSettingsOpen(true)
  }

  const sidebarItems = pathSettings.map((setting) => {
    const { name, icon, path } = setting
    if (name !== 'Settings') {
      return (
        <SidebarItem
          text={name}
          path={path}
          icon={icon}
          onClick={handleNavigate}
          location={location}
          key={name}
        />
      )
    }
  })

  return (
    <StyledDrawer
      open={isOpen}
      onClose={() => toggleSidebar(false)}
      variant={isPersistent ? 'persistent' : 'temporary'}
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <AppLogoSidebar />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              style={{ textTransform: 'uppercase' }}
              variant="h6"
              // gutterBottom
            >
              {title}
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Divider variant="middle" />
      <List>{sidebarItems}</List>
      <BottomButtonContainer>
        <FullscreenButton toggleSidebar={toggleSidebar} />
        <Tooltip title="Settings">
          <SettingsButton onClick={handleSettingsClick}>
            <SettingsIcon color="secondary" />
          </SettingsButton>
        </Tooltip>
      </BottomButtonContainer>
    </StyledDrawer>
  )
}

export default Sidebar

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    width: 200px;
    background-color: ${(p) => p.theme.palette.background.default}eb;
    @supports (backdrop-filter: none) {
      background-color: ${(p) => p.theme.palette.background.default}bb;
      backdrop-filter: blur(12px);
    }
  }
`
const BottomButtonContainer = styled.div`
  position: relative;
  height: 100%;
`
const SettingsButton = styled(IconButton)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: ${(p) => p.theme.zIndex.drawer + 101};
`
